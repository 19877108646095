import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import Article from "../components/Article";
import ArticleHeader from "../components/ArticleHeader";
import { breakpoints } from "../constants/breakpoints";

const Standardinfoblatt = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Standardinformationen" />
            <Article>
                <Table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>Identität und Anschrift:</strong>
                            </td>
                            <td>Miracl GmbH Lindengasse 26/1/5, 1070 Wien Miracl.at</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Registrierung:</strong>
                            </td>
                            <td>
                                Eingetragen im GewerbeInformationsSystem Austria (GISA) mit der Registernummer: 34094204
                                Internetadresse: https://www.gisa.gv.at/vkr
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Art der Kreditvermittlung:</strong>
                            </td>
                            <td>Ungebundener Kreditvermittler</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Beratungsdienstleistungen:</strong>
                            </td>
                            <td>
                                Es werden Beratungsdienstleistungen angeboten. Die Empfehlung bezieht sich auf eine
                                größere Auswahl von Kreditverträgen/Produkten am Markt.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Beschwerdemöglichkeit:</strong>
                            </td>
                            <td>
                                Bei Beschwerden kann die Ombudsstelle des Fachverbands Finanzdienstleister unter
                                fdl.ombudsstelle@wko.at in Anspruch genommen werden. Darüber hinaus besteht die
                                Möglichkeit der alternativen Streitbeilegung durch die Schlichtung für
                                Verbrauchergeschäfte.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Entgelt:</strong>
                            </td>
                            <td>
                                Für die Kreditvermittlungstätigkeit erhält der Kreditvermittler im Falle eines
                                Kreditabschlusses eine Provision vom Kreditgeber. Die Provision steht dem
                                Kreditvermittler als Vergütung seiner Dienstleistung zu. Der tatsächliche Betrag der
                                Provision ist vor Ausübung der Kreditvermittlungstätigkeit noch nicht bekannt, der
                                Betrag wird im ESIS-Merkblatt (Europäisches Standardisiertes Merkblatt) angegeben.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Informationserteilung seitens Kreditsuchendem:</strong>
                            </td>
                            <td>
                                Für eine Kreditvergabe sind Informationen und unabhängig nachprüfbare Nachweise für die
                                Kreditvergabe vom Kreditsuchenden beizubringen, diese Angaben müssen korrekt und so
                                vollständig sein, dass diese für eine ordnungsgemäße Kreditwürdigkeitsprüfung
                                ausreichen. WARNUNG: Der Kredit kann nicht gewährt werden, wenn sich der Kreditsuchende
                                weigert, diese Informationen oder Nachweise vorzulegen.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Ersichtlichmachung der Höchstbeträge:</strong>
                            </td>
                            <td>
                                <span>
                                    Gemäß § 8 der Standesregeln für die Kreditvermittlung, Fassung vom 26.01.2017
                                    (Verordnung des Bundesministers für Wissenschaft, Forschung und Wirtschaft über
                                    Standes- und Ausübungsregeln für gewerbliche Vermögensberater und Immobilienmakler,
                                    die die Tätigkeit der Kreditvermittlung ausüben) (BGBl. II Nr. 86/2016) haben
                                    Kreditvermittler in den für den Verkehr mit Verbrauchern bestimmten Räumen die für
                                    Kreditvermittlungen zulässigen Höchstsätze der Provisionen oder sonstige Vergütungen
                                    mit dem ausdrücklichen Hinweis, dass es sich um Höchstbeträge handelt, ersichtlich
                                    zu machen. Dieser Verpflichtung gemäß § 8 der zitierten Standesregeln für
                                    Kreditvermittlung kommt der Kreditvermittler sohin wie folgt nach:
                                    <ul>
                                        <li>
                                            Die Provision oder sonstige Vergütung von Personalkrediten darf 5% der
                                            Bruttokreditsumme nicht übersteigen.
                                        </li>
                                        <li>
                                            In dem der Berechnung zugrunde zu legenden Bruttokreditbetrag dürfen keine
                                            Zinsen enthalten sein.
                                        </li>
                                    </ul>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Article>
        </Layout>
    );
};

const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid #ededed;
    color: #677294;
    word-break: keep-all;

    td {
        border-top: 1px solid #ededed;
        padding: 10px 5px;
        vertical-align: top;
    }
    .no-border td {
        border-top: none;
    }

    @media (max-width: ${breakpoints.mobileMax}) {
        td {
            display: block;
            padding: 8px 0;
            font-size: 14px;
        }
        .no-border td {
            border-top: none;
            display: inline-block;
            padding-top: 3px;

            :first-child {
                margin-right: 5px;
            }
        }
    }
`;

export default Standardinfoblatt;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.standardinfoblatt"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
